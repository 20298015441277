import React, { ChangeEvent }  from "react";
import { Input, 
  Button, 
  Divider, 
  Text, 
  ButtonProps, 
  Image,
  Link, 
  tokens, 
  InputOnChangeData, 
  Spinner, 
  Dialog,
  DialogTrigger,
  DialogBody,
  DialogTitle,
  DialogActions,
  DialogSurface,
  DialogContent,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Textarea,
  CardPreview,
  Card,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  CardFooter,
  useId} from "@fluentui/react-components";

import {
  Send16Regular,  
  ThumbDislike16Regular,
  ThumbDislike16Filled,
  ThumbLike16Regular,
  ThumbLike16Filled,  
  Delete16Regular,
  Dismiss16Regular,
  Copy16Regular,
  Clipboard16Regular,
  ChatAdd24Filled,
  TextBulletListSquare24Filled,
} from "@fluentui/react-icons";
import moment from"moment"
import { AxiosInstance, createApiClient } from "@microsoft/teamsfx";
import { ChatHistoryModel, SearchResultModel } from "../models/SearchResultModel";
import { DocumentModel } from "../models/DocumentModel";
import { ChatModel, LikeState } from "../models/ChatModel";
import { EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import ApiKeyAuthProvider from "../Authentication/ApiKeyAuthProvider";
import { ApiResponse } from "../models/ApiResponse";
import { AuthenticatedTemplate, UnauthenticatedTemplate, withMsal } from "@azure/msal-react";
import { loginRequest } from "../Authentication/MsalConfiguration";
import { app } from "@microsoft/teams-js";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { appInsights, reactPlugin } from "../ApplicationInsight/ApplicationInsightsService";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import ChatHistory from "./ChatHistory";
import ChatWelcome, { WelcomeAction } from "./ChatWelcome";
import Markdown from "react-markdown";
import rehypeExternalLinks from "rehype-external-links";

type ChatState = {
  prompt: string,
  searchResults: SearchResultModel[],
  chatHistory: ChatHistoryModel[],
  chatHistoryOpen: Boolean,
  currentChat: ChatModel[],
  startupOptions: WelcomeAction[],
  loading: boolean,
  scrollToLast: boolean,
  comment: string,
  commentedRequestId: string,
  isCommentDialogOpen: boolean,
  isFileUploadDialogOpen: boolean,
  isFileUploadSuccessDialogOpen: boolean,
  chatListRef: React.MutableRefObject<HTMLUListElement | null>,
  fileInputRef: React.MutableRefObject<HTMLInputElement | null>,
  callbackId?: string,
  isUploadingFile: boolean,
  userId: string,
  userMail: string,
  isAuthenticated: boolean
  initializing: boolean
}



 class Chat extends React.Component<any, ChatState> {
 
  apiClient: AxiosInstance | undefined;
  uploadFileApiClient: AxiosInstance | undefined;
  toasterId = "toaster";
  //dispatchToast  = useToastController(this.toasterId);

  constructor(props: any) {
    super(props);
    this.state = { 
      prompt: '', 
      searchResults:[], 
      chatHistory: [],
      chatHistoryOpen: false,
      currentChat: [],
      startupOptions: [],
      scrollToLast: false,
      isCommentDialogOpen: false,
      isFileUploadDialogOpen: false,
      isFileUploadSuccessDialogOpen: false,
      chatListRef: React.createRef<HTMLUListElement>(),
      fileInputRef:  React.createRef<HTMLInputElement>(),
      comment: "",
      commentedRequestId: "",
      loading: false,
      isUploadingFile: false,
      userId: "",    
      userMail: "",
      isAuthenticated: false,
      initializing: true };        
      this.setupApiClient();    
  }


    // Setup the API client
    setupApiClient() : void {
      const apiKey  = "NUjFZgcXzYhVA1vDPSIdrL-GARq9T3knRDi_fM0H_nZ3AzFuCwkFgA=="; //process.env.REACT_APP_API_KEY ?? "";
      const apiHeader  = process.env.REACT_APP_API_HEADER ?? "";   
      const apiEndpoint  = process.env.REACT_APP_API_ENDPOINT ?? "";
      const authProvider = new ApiKeyAuthProvider(apiHeader, apiKey, this.state.userMail, this.state.userId, "application/json");
      this.apiClient = createApiClient(apiEndpoint, authProvider);
      this.apiClient.defaults.timeout = 60000;
      this.apiClient.defaults.timeoutErrorMessage = "Ocurrió un error al realizar la consulta. Por favor intente nuevamente.";

      console.log("process.env.REACT_APP_API_HEADER " + process.env.REACT_APP_API_HEADER);
      console.log("process.env.REACT_APP_API_ENDPOINT)  " + process.env.REACT_APP_API_ENDPOINT);
      const updateFileAuthProvider = new ApiKeyAuthProvider(apiHeader, apiKey, this.state.userMail, this.state.userId, "multipart/form-data");
      this.uploadFileApiClient = createApiClient(apiEndpoint, updateFileAuthProvider);
      this.uploadFileApiClient.defaults.timeout = 60000;
      this.uploadFileApiClient.defaults.timeoutErrorMessage = "Ocurrió un error al realizar la consulta. Por favor intente nuevamente.";
      
    }

  // Component initialization
  // Setups the callback for the login success event
  override async componentDidMount(): Promise<void> {  

     // Handle the message event when authentication is successful with msal
    const callbackId = this.props.msalContext.instance.addEventCallback((message :any) => {     
        if (message.eventType === EventType.LOGIN_SUCCESS 
          || message.eventType === EventType.SSO_SILENT_SUCCESS) {

          // Saves the user id and email on the state
          const msalInstance = this.props.msalContext.instance;
          const activeAccount = msalInstance.getActiveAccount();       
          const userId =  activeAccount?.localAccountId ?? ""; 
          const userMail = activeAccount?.username ?? ""; 
          this.ShowWelcomeMessage(userMail, userId);                
        }
    });
    this.setState({callbackId: callbackId});

    const msalInstance = this.props.msalContext.instance;
    const activeAccount = msalInstance?.getActiveAccount();

    if (activeAccount!=null){
      const userId =  activeAccount?.localAccountId ?? ""; 
      const userMail = activeAccount?.username ?? ""; 
      this.ShowWelcomeMessage(userMail, userId);    
    }
    else{
      // Tries to authenticate the user via Teams App
      try{
        var context = await app.getContext();
        if(context.user!=null){
          const userId =  context.user?.id ?? "";
          const userMail =  context.user?.userPrincipalName ?? "";
          this.ShowWelcomeMessage(userMail, userId);         
        }
        else
        {
          // Attempt login via mal
          console.log("trying silent login");  
        }
      }
      catch(error){
        // Unable to authenticate use msal      
        console.log(error);    
      }   
    }
}


  ShowWelcomeMessage(userMail: string, userId: string) : void {
    this.setState({userId: userId, userMail: userMail, isAuthenticated: true, initializing: false}, () =>{
        
        // Gest the chat history          
        this.setupApiClient();     
        this.apiClient?.get<ApiResponse>("/startup/options").then( (response: any) => {
          const options = response.data?.data?.map( (d: any): WelcomeAction =>({
            description: d.title,
            imageUrl: d.icon,
            title: "Pregunta"
          }));
          this.setState({startupOptions: options});
        }).catch((err: any) => {
          this.showErrorMessage(err);
        });            
        this.apiClient?.get<ApiResponse>("chat/history").then((response: any) => {
          if (response?.status == 200) {
            const result: any[] = response.data.data;
            this.setState({chatHistory: result.reverse(), prompt: "", searchResults: result });  
          }
          else
          {
            this.showErrorMessage(response?.status);
            this.addBotGreetingMessage();
          }
        }).catch((err: any) => {
          this.showErrorMessage(err);
        });
        
      });
  }
 

   // This will be run on component unmount
  componentWillUnmount() {     
      if (this.state.callbackId) {
          this.props.msalContext.instance.removeEventCallback(this.state.callbackId);
      }
  }


  // Show an error message
  showErrorMessage(err: string): void {
    this.addMessageToChat("00000000-0000-0000-0000-000000000000", "bot", 
    "Lo sentimos, pero ocurrió un error con el servicio (" + err + ")", [], false, false, 0, 0,  "", [], LikeState.None, moment());   
  }

  // Add the bot greeting message
  addBotGreetingMessage() : void{ 
    this.addMessageToChat("00000000-0000-0000-0000-000000000000", "bot", 
    "Hola, soy ColBot, el asistente personal de Colbún, en que te puedo ayudar?", [], false, false, 0, 0, "1",[], LikeState.None, moment());  
  }

  clearChat(): void {
    while( this.state.currentChat.length > 0){
      this.state.currentChat.pop();
    }
    this.setState({currentChat: this.state.currentChat});
  }

  restoreConversation(conversationId: string): void {
    const conversation = this.state.chatHistory.find( c => c.conversationId === conversationId )
    if (conversation){
      this.clearChat();
      for (const message of conversation.history){
        this.addMessageToChat(message.requestId, "user", message.prompt, [], false, true, 0, 0, "", [], LikeState.None, moment(message.requestDate));
        this.addMessageToChat(message.requestId, "bot", message.response, [], true, false, 
          message.tokens, message.requestTime, "", message.suggestedQuestions, LikeState.None, moment(message.requestDate));
      }
      // var momentDate = moment(conversation.requestDate);
      // this.addMessageToChat("00000000-0000-0000-0000-000000000000", "user", 
      //   conversation.prompt, [], false, true, 0, 0, "", [], LikeState.None, momentDate);

      // this.addMessageToChat(conversation.requestId, "bot", conversation.response, conversation.documents,  true, false, 
      //   conversation.tokens, conversation.requestTime, conversation.backendVersion, conversation.suggestedQuestions, 
      //   conversation.liked, momentDate);    
    }
  }

  runSuggestedPrompt(prompt: string)  {
    this.clearChat();
    this.searchDocument(prompt);
  }

  clearPrompt(): void {
    this.setState({ prompt: "" });
  }

  // Return the search button
  SearchButton: React.FC<ButtonProps> = (props) => {
    return (
        <div style={{flexShrink: 0}}>{
          this.state.loading ? 
            <Spinner size="tiny"/> : 
            <div>
              <Button
                  {...props}
                  appearance="transparent"
                  icon={<Dismiss16Regular />}
                  size="small"
                  onClick={() => this.clearPrompt()}
              />
            <Button
                  {...props}
                  appearance="transparent"
                  icon={<Send16Regular />}
                  size="small"
                  onClick={() => this.searchDocument(this.state?.prompt)}
              />
            </div>       
        }
       </div>         
    );
  };


  // Copy to clipboard button component
  CopyToClipboardButton: React.FC<ChatModel> = (props) => {
    return (
        <div>{  
          <Button
                {...props}
                appearance="transparent"
                icon={<Copy16Regular />}              
                size="small"/>                
                
        }
        </div>         
    );
  };


  // Like  button component
  LikeButton: React.FC<ChatModel> = (props) => {
    return (
        <div>{  
          <Button
                {...props}
                appearance="transparent"
                size="small"     
                icon={ props.likeState == LikeState.None || props.likeState == LikeState.Dislike ? 
                  <ThumbLike16Regular/> : <ThumbLike16Filled primaryFill={tokens.colorBrandForegroundInvertedHover}/>}
                onClick={async () => {
                  if (await this.likeOrDislikeSearchResult(props.requestId, true, "")){
                    props.likeState = LikeState.Like;
                    this.forceUpdate();
                  }
                }}                           
            />      
        }
       </div>         
    );
  };

  // Dislike  button component
  DislikeButton: React.FC<ChatModel> = (props) => {       
    return (
        <div>{  
          <Dialog open={this.state?.isCommentDialogOpen}  
                  onOpenChange={(e, data)=> this.setState({isCommentDialogOpen: data.open, commentedRequestId: props.requestId})}>
              <DialogTrigger disableButtonEnhancement>
                <Button
                      appearance="transparent"
                      icon={ props.likeState == LikeState.None || props.likeState == LikeState.Like ? 
                        <ThumbDislike16Regular/> : <ThumbDislike16Filled primaryFill={tokens.colorBrandForegroundInvertedHover}/>}
                      size="small"                
                  />   
              </DialogTrigger>
              <DialogSurface>
              <DialogBody>
                <DialogTitle>Enviar un comentario</DialogTitle>
                <DialogContent>
                    <Textarea value={this.state?.comment} appearance="outline" style={{width:"100%", height:"100px"}}
                      placeholder="Indícanos como podríamos mejorar la calidad de respuesta"
                      maxLength={500}
                      resize="both"
                      onChange={(ev, data)=>
                        this.setState({ comment: data.value})}/>
                </DialogContent>
                <DialogActions>
                  <DialogTrigger disableButtonEnhancement>
                    <Button appearance="secondary">Cerrar</Button>
                  </DialogTrigger>
                  <Button  
                    {...props} 
                    appearance="primary"    
                    onClick={ async ()=> {
                      var result = await this.likeOrDislikeSearchResult(this.state?.commentedRequestId, false, this.state?.comment);
                      if (result){
                        this.setState({isCommentDialogOpen: false, comment: "", commentedRequestId:""});

                        // find and update the like state of the proper request
                        var chatElement = this.state?.currentChat.find((c: ChatModel) => c.requestId == this.state?.commentedRequestId);
                        if (chatElement!=null){
                          chatElement.likeState = LikeState.Dislike;
                          this.forceUpdate();          
                        }                     
                      } 
                    }}>
                    Enviar comentario
                  </Button>
                </DialogActions>
              </DialogBody>
            </DialogSurface>
          </Dialog>   
        }
        </div>         
    );
  };
  


  // Performs the document search
  async searchDocument(prompt: string): Promise<void> { 
    if (prompt!=null && prompt.length > 0)
    {
        // var today = new Date();
        // var time = today.getHours() + ":" + today.getMinutes();
        var time = moment().format('LT'); 
        
        this.addMessageToChat("00000000-0000-0000-0000-000000000000", "user", prompt, [], false, true, 0, 0, "", [], LikeState.None, moment());
        appInsights.trackEvent({ name: 'Query', properties: { userEmail: this.state.userMail, query: prompt } });         


        this.addMessageToChat("00000000-0000-0000-0000-000000000001", "bot", 
          "Consultando " + "**" +  prompt +"**", [], false, true, 0, 0, "", [], LikeState.None, moment());  

        var response = await this.apiClient?.post<ApiResponse>("query", { "Prompt": prompt })
          .catch((err: any) => {
            this.showErrorMessage(err);
          });

        // Removes the last message
        this.state.currentChat.pop();

       if (response?.status == 200) {
        var result = response.data.data;       
        this.addMessageToChat(result.requestId, "bot", result.summary, result.documents, true, false, 
          result.tokens, result.requestTime, result.backendVersion, result.suggestedQuestions, LikeState.None, moment());    
        this.setState({ prompt: "", searchResults: [result] });
        
        // this.state.chatHistory.unshift({
        //   prompt: result.prompt,
        //   requestId: result.requestId,
        //   response: result.summary,
        //   liked: LikeState.None,
        //   tokens: result.tokens,
        //   requestTime: result.requestTime,
        //   backendVersion: result.backendVersion,
        //   requestDate: new Date(),
        //   suggestedQuestions: result.suggestedQuestions,
        //   documents: result.documents,
        // });
      }
      else
      {
        this.addMessageToChat("00000000-0000-0000-0000-000000000000", "bot", 
          "Lo sentimos, pero ocurrió un error al realizar la consulta", [], false, false, 0, 0, "", [], LikeState.None, moment());   
      }
    }
  };


  addMessageToChat(requestId: string, role: string, message: string, 
    documents: DocumentModel[], 
    canLike: boolean, isLoading: boolean, tokens: number, requestTime: number,
    backendVersion: string, suggestedQuestions: string[], likeState: LikeState, dateMoment: moment.Moment) : void{

    var date = dateMoment.date() === moment().date() ? dateMoment.format('HH:mm') : dateMoment.format('DD/MM/YYYY HH:mm'); 
    this.state.currentChat.push({ 
      requestId,
      role,  
      likeState,
      canLike,
      date, 
      message, 
      documents,
      suggestedQuestions,
      backendVersion,
      tokens,
      requestTime
     });
     this.setState({  loading: isLoading }, ()=>
     {
       this.state?.chatListRef.current?.lastElementChild?.scrollIntoView();      
     });          
  }


  ///Upload the zip file
 handleUploadDocument = (event: any)=>{

    //formData.append("file", file);
    var file = event.target.files[0];

    let formData = new FormData();
    formData.append("file", file);
    this.setState({isUploadingFile: true});

    this.uploadFileApiClient?.post<ApiResponse>("approvals/request", formData)
    .then((response: any) => {
      if (response?.status == 200) {
        // Show a success message
        this.setState({isUploadingFile: false, isFileUploadDialogOpen: false, isFileUploadSuccessDialogOpen: true});   
      }
    })
    .catch((err: any) => {
      // Show an error
      console.log(err);
    })
    .finally(() => {
   //   this.setState({isUploadingFile: false, isFileUploadDialogOpen: false, isFileUploadSuccessDialogOpen: true});   
    });      
  }


  // Call the API to like or dislike a search result
  async likeOrDislikeSearchResult(requestId:string, liked: boolean, comment:string ) 
  {
    if (liked || (!liked && comment.length > 0)){    
      const response = await this.apiClient?.post<ApiResponse>("query/like",  { "requestId": requestId, liked: liked, comment: comment });
      if (response?.status == 200) {
        return true;
      }
      else{
        console.log("Error " + response?.status);
        return false;
      } 
    }
  }


  // Handle the enter
  onInputChange =(ev: ChangeEvent<HTMLInputElement>, data: InputOnChangeData) =>{
    this.setState({ prompt: data.value, searchResults: this.state.searchResults });   
  };

  openChatHistory = () => this.setState({chatHistoryOpen: true});
  closeChatHistory = () => this.setState({chatHistoryOpen: false});

  
  // Render the component
  render() {
      
     return (
          <div className="chat-container">      
            {!this.state.initializing && this.state.isAuthenticated ?
              <>
              {!this.state.currentChat || this.state.currentChat.length === 0 ? 
                  <ChatWelcome 
                    actions={this.state.startupOptions}
                    onActionSelected={(action) => this.runSuggestedPrompt(action.description)}
                  />
                :
              <ul ref={this.state?.chatListRef} 
                  style={{
                  listStyle:"none", 
                  padding: "0px",
                  overflowY:"scroll",
                  scrollbarWidth:"none",                 
                  scrollBehavior:"smooth"}}>{
                    this.state?.currentChat?.map((t: ChatModel) => {     
                      return (
                        <div>                    
                          {t.role == "bot" ? (                      
                            <li style={{
                                overflow:"hidden",
                                margin: "0px 80px 0px 0px",
                                display:"flex"}}>
                                <div>                                                        
                                  <Image
                                    style={{margin: "20px 6px 0px 0px"}}
                                    alt="bot"
                                    src="./botIcon.png"
                                    height={25}
                                    width={25}
                                  />
                                </div>
                                <div>
                                  <Text size={200}>
                                    ColBot {t.date}
                                  </Text>                               
                                    <div  style={{                                      
                                      margin: "4px 0px 0px 0px",                                                                                                                           
                                  }}>                                  
                                    <Card>
                                      <CardPreview>
                                        <p style={{ padding: '0px 18px 0px 18px'}}>
                                        {/* <div  dangerouslySetInnerHTML={{__html: this.formatMessageAsHtml(t.message)}}>                             
                                        </div>                                        */}                                       
                              
                                       <Markdown rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}
                                                  children={t.message}/>

                                        {/* Documentos relacionados            */}
                                        {t?.documents?.length > 0 ? 
                                        <div style={{marginTop:"6px"}}>
                                          <Accordion collapsible={true} >
                                              <AccordionItem value="1" >
                                                <AccordionHeader>
                                                  Encontré los siguientes documentos relacionados con tu consulta
                                                </AccordionHeader>
                                                <AccordionPanel>
                                                  <div>
                                                {t?.documents?.map((d: DocumentModel) => {
                                                        return (
                                                          <li style={{marginBottom: "6px"}}>
                                                            <Button shape="rounded" onClick={()=>{
                                                                 window.open(d.documentUri, '_blank');
                                                            }}>
                                                              {/* <Link href={d.documentUri} target="_blank"> */}
                                                                <Text style={{color: tokens.colorNeutralForeground2BrandPressed}}  size={300}> 
                                                                  {d.file} 
                                                               </Text>                                     
                                                              {/* </Link> */}
                                                            </Button>
                                                          </li>
                                                        );
                                                      })
                                                  }
                                                  </div>                                         
                                                  </AccordionPanel>
                                              </AccordionItem> 
                                          </Accordion>                                      
                                        </div>                                      
                                      :<div/>}

                                        {/* Preguntas sugeridas     */}  
                                        {t?.suggestedQuestions?.length > 0 ? 
                                        <div>
                                          <Accordion collapsible={true} >
                                          <AccordionItem value="1" >
                                            <AccordionHeader>
                                              Otras preguntas relacionadas que pueden ser de tu interés
                                              </AccordionHeader>
                                            <AccordionPanel>
                                            {/* <ul style={{marginTop:"6px"}}>                          */}
                                            {t?.suggestedQuestions?.map((d: string) => {
                                              return (
                                                <div style={{marginBottom:"6px"}}>
                                                  <li>
                                                  <Button shape="rounded" onClick={() => {
                                                      this.setState({prompt: d});
                                                      this.searchDocument(d);
                                                  }}>
                                                    {/* <Link onClick={() => {
                                                      this.setState({prompt: d});
                                                      this.searchDocument(d);
                                                    }}>{d}</Link> */} 
                                                    <Text style={{color: tokens.colorNeutralForeground2BrandPressed}} size={300} > 
                                                      {d}
                                                    </Text>
                                                    </Button>
                                                  </li> 
                                                </div>
                                              );
                                            })}   
                                          {/* </ul>   */}
                                            </AccordionPanel>
                                          </AccordionItem>
                                          </Accordion>                                     
                                        </div>
                                        : <></>  
                                      }

                                    </p>
                           
                                      {t?.canLike ? 
        
                                        <div style={{
                                          display:"grid",
                                          marginRight: "12px",
                                          marginBottom: "6px",
                                       
                                          gridTemplateRows: "auto auto"}}>     
                                                                 
                                          <div style={{
                                              display:"grid",
                                              gridTemplateColumns: "auto 24px 24px"}}>                                                                                                             
                                                  <Text size={200} 
                                                    align="end"
                                                      style={{paddingTop:"2px", marginRight: "4px"}}>
                                                          Ten en cuenta que los resultados generados por AI pueden no ser exactos / {t.tokens} tokens / {t.requestTime} segundos. 
                                                  </Text>  
                                                  {this.DislikeButton(t, t.requestId)}  
                                                  {this.LikeButton(t)}                                                                                  
                                            </div>                                         
                                          </div>  
                                          : <></>
                                        }
                                    
                                      </CardPreview>
                                    </Card>
                                    <div>                                   
                                    </div>                                 
                                   
                                </div>
                              </div>
                            </li>
                          ) : (
                            <li style={{
                              display:"flex",
                              overflow:"hidden",
                              justifyContent:"flex-end", 
                              alignItems:"flex-end"}}>                            
                                <div>
                                    <div style={{textAlign:"end", margin:"4px 24px 4px 0px"}}>
                                      <Text size={200}>{t.date}</Text>
                                    </div>              
                                    <div style={{
                                      padding: '6px 12px 6px 12px',
                                      margin: "0px 24px 0px 0px",
                                      borderRadius: "5px",
                                      backgroundColor: tokens.colorBrandForegroundInvertedHover
                                    }}>
                                    <Text color={tokens.colorBrandForegroundOnLight} size={300}>{t.message}</Text>
                                  </div>
                                </div>
                            </li>
                          )}
                        </div>
                      );
                    })
                    }
              </ul>
              }
              <div style={{height: "fit-content", width: "100%", gridArea: "input"}}>
                <Divider  style={{margin: '0px 12px 12px 0px'}}/>
                <div style={{display: 'flex'}}>
                  <Input value={this.state.prompt} onChange={this.onInputChange} 
                    style={{flexGrow: '1'}} placeholder="¿En que puedo ayudarte?" 
                    contentAfter={<this.SearchButton/>} 
                    onKeyDown={(e) => { if (e.key === 'Enter') { this.searchDocument(this.state?.prompt) } }}
                    disabled={this.state?.loading} maxLength={250}/> 
                  <Button
                    appearance="transparent"
                    icon={<ChatAdd24Filled />}
                    onClick={() => this.clearChat()}
                  />
                  <Button
                    appearance="transparent"
                    
                    icon={<TextBulletListSquare24Filled />}
                    size="small"
                    className="history-container-opener"
                    onClick={() => this.openChatHistory()}
                  />  
                  
                </div>
                  {/* <div style={{padding: '6px 0px 0px 0px'}} >
                      <Text size={200} weight="bold" style={{color: tokens.colorNeutralForeground2BrandPressed}}>
                          ColBot utiliza AI para responder, por lo que es posible que se produzcan errores. 
                      </Text>  
                    </div>           */}

                  <div style={{
                    margin: "2px 0px 0px 0px",
                    display: "grid",
                    gridTemplateColumns: "80% 20%"}}>

                  <Dialog open={this.state?.isFileUploadDialogOpen} 
                        onOpenChange={(e, data)=> this.setState({isFileUploadDialogOpen: data.open})}>
                      <DialogTrigger disableButtonEnhancement>
                        <Text size={200}>
                          ¿Quieres sugerir documentos para la base de conocimientos? <Link>haz click aquí</Link>
                        </Text>                    
                      </DialogTrigger>
            
                      <DialogSurface>
                      <DialogBody>
                        <DialogTitle>
                          Sugerir nuevos documentos                      
                        </DialogTitle>
                        <DialogContent>
                          <Text size={300}>
                              Hola! Si tienes algún documento que crees que sería genial añadir a nuestra base de conocimientos, ¡nos encantaría verlo!<br/> <br/> 
                              Puedes subir archivos en formatos PDF, Word o PowerPoint, e incluso si tienes varios, ¡no hay problema! Simplemente colócalos todos en una carpeta comprimida (.zip) y subirlos. ¡Estamos emocionados por ver lo que compartes!
                            </Text>  
                        </DialogContent>                                          
                        <DialogActions>
                          <DialogTrigger disableButtonEnhancement>
                            <Button  
                              disabled={this.state?.isUploadingFile} 
                              appearance="secondary">Cerrar</Button>
                          </DialogTrigger>
                            <Button 
                                 disabled={this.state?.isUploadingFile}
                                 appearance="primary" onClick={() => {
                                 this.state?.fileInputRef.current?.click();
                              }}>                           
                              Subir archivo    
                              { this.state?.isUploadingFile ?   
                                  <Spinner style={{marginLeft:"6px"}} size="tiny"/> : <></>
                              }
                           
                            </Button>
                            <input type="file" accept=".zip, .docx, .pdf" onChange={this.handleUploadDocument} 
                              multiple={false} ref={this.state?.fileInputRef}
                              style={{ display: "none" }} id="upload" hidden />
                        </DialogActions>
                        </DialogBody>
                      </DialogSurface>
                  </Dialog>

                  <Dialog open={this.state?.isFileUploadSuccessDialogOpen} 
                        onOpenChange={(e, data)=> this.setState({isFileUploadSuccessDialogOpen: data.open})}>
                      <DialogSurface>
                      <DialogBody>
                        <DialogTitle>
                          Archivo enviado exitosamente      
                        </DialogTitle>
                        <DialogContent>
                          <Text size={300}>                            
                            Hemos recibido tu solicitud y está en proceso de revisión. Una vez que sea aprobada, la añadiremos a nuestra base de conocimientos. 😊<br/><br/>
                            Si quieres estar al tanto del estatus de tu solicitud, puedes hacerlo fácilmente a través de la aplicación "Aprobaciones" en Microsoft Teams. ¡Gracias por tu paciencia! 🌟
                            </Text>  
                        </DialogContent>    
                        <DialogActions>
                          <DialogTrigger disableButtonEnhancement>
                            <Button                            
                              appearance="primary">Aceptar</Button>
                          </DialogTrigger>
                        </DialogActions>
                        </DialogBody>
                      </DialogSurface>
                  </Dialog>
                   <Text size={200} align="end">
                      {this.state.prompt.length} / 250
                    </Text>
                    <Toaster
                      toasterId={this.toasterId}
                      position="top-end"
                      pauseOnHover
                      pauseOnWindowBlur
                      timeout={1000}
                    />     
                      
                  </div>
             
              </div>
              <div style={{gridArea: "side-panel", overflowY: "scroll"}}>
                  <ChatHistory 
                    isOpen={this.state.chatHistoryOpen}
                    elements={this.state.chatHistory} 
                    onChatSelected={(chat) => this.restoreConversation(chat.conversationId)} 
                    onClose={ () => this.closeChatHistory()}
                  />
              </div>
              </>
              :
     
                  <>     
                  
                    <div style={{
                        display: "grid",
                        gridTemplateRows: "30px 36px", 
                        padding: "24px"}}>
                          <Text>
                            Debe iniciar sesión para continuar
                          </Text>
                          <div>                          
                            <Button onClick={async () =>{
                              const msalInstance = this.props.msalContext.instance;                            
                                msalInstance.loginPopup(loginRequest).catch((err: any) => {
                                  console.error(err);
                                });                          
                          }}>Iniciar con cuenta Colbún S.A</Button>   
                        </div>                    
                    </div>             
                </>
           
            }     
          </div>   
     );
  }

  formatMessageAsHtml(message: string) : string{
    var htmlMessage = message.replace(/(\n\n)/g, "\n");
    var htmlMessage = htmlMessage.replace(/(\r\n|\r|\n)/g, '<br>');
    return htmlMessage;
  }  
}

//export default withMsal(Chat);
export default withAITracking(reactPlugin, withMsal(Chat));
