import React from "react";
import { 
  Text, 
  Button

  } from "@fluentui/react-components";
import ApiKeyAuthProvider from "../Authentication/ApiKeyAuthProvider";
import { AxiosInstance, createApiClient } from "@microsoft/teamsfx";
import { EventType } from "@azure/msal-browser";
import { app } from "@microsoft/teams-js";
import { ApiResponse, ChangelogResponse } from "../models/ApiResponse";
import { loginRequest } from "../Authentication/MsalConfiguration";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { withMsal } from "@azure/msal-react";
import { reactPlugin } from "../ApplicationInsight/ApplicationInsightsService";
import ReactMarkdown from 'react-markdown'

type ChangeLogState = {
  userId: string,
  userMail: string,
  isAuthenticated: boolean,
  callbackId?: string,
  initializing: boolean,
  changeLog: string
};

// Component to display the changelog
class Changelog extends React.Component<any, ChangeLogState> {

    apiClient: AxiosInstance | undefined;

    constructor(props: any) {
      super(props);
      this.state = {      
        userId: "",    
        userMail: "",
        isAuthenticated: false,
        initializing: false,
        changeLog: ""
      };           
    }

    // Setup the API client
    setupApiClient() : void {
      const apiKey  = "NUjFZgcXzYhVA1vDPSIdrL-GARq9T3knRDi_fM0H_nZ3AzFuCwkFgA=="; //process.env.REACT_APP_API_KEY ?? "";
      const apiHeader  = process.env.REACT_APP_API_HEADER ?? "";   
      const apiEndpoint  = process.env.REACT_APP_API_ENDPOINT ?? "";
      const authProvider = new ApiKeyAuthProvider(apiHeader, apiKey, this.state.userMail, this.state.userId, "application/json");
      this.apiClient = createApiClient(apiEndpoint, authProvider);
      this.apiClient.defaults.timeout = 60000;
      this.apiClient.defaults.timeoutErrorMessage = "Ocurrió un error al realizar la consulta. Por favor intente nuevamente.";
  }

  // Component initialization
  // Setups the callback for the login success event
  override async componentDidMount(): Promise<void> {  

    // Handle the message event when authentication is successful with msal  
    const callbackId = this.props.msalContext.instance.addEventCallback((message :any) => {     
        if (message.eventType === EventType.LOGIN_SUCCESS) {

          // Saves the user id and email on the state
          const msalInstance = this.props.msalContext.instance;
          const activeAccount = msalInstance.getActiveAccount();
          const userId =  activeAccount?.localAccountId ?? ""; 
          const userMail = activeAccount?.username ?? ""; 
          this.GetChangeLog(userMail, userId);           
        }
    });
    this.setState({callbackId: callbackId});
    

    // Tries to authenticate the user via Teams App
    try{
      var context = await app.getContext();
      if(context.user!=null){
        const userId =  context.user?.id ?? "";
        const userMail =  context.user?.userPrincipalName ?? "";
        this.GetChangeLog(userMail, userId);       
      }
    }
    catch(error){
      // Unable to authenticate use msal      
      console.log(error);    
    }   
  }

  
  // This will be run on component unmount
  componentWillUnmount() {     
    if (this.state.callbackId) {
        this.props.msalContext.instance.removeEventCallback(this.state.callbackId);
    }
  }


  GetChangeLog(userMail: string, userId: string)  {
    this.setState({userId: userId, userMail: userMail, isAuthenticated: true, initializing: false}, async () =>{        
        this.setupApiClient();         
        const response = await this.apiClient?.get<ChangelogResponse>("changelog");
        if (response?.status == 200) {
          var changelog = response.data.data;
          this.setState({changeLog: changelog});;
          return true;
        }
        else{
          this.setState({changeLog: "Ocurrió un error al obtener el historial de cambios"});;
          return false;
        } 
      });
  }
  
 

  render() {
    return (
      <div style={{
        display: "grid",
        height: "100vh",
        overflow: "auto",
        padding: '0px 24px 24px 24px',
      }}>      
         {!this.state.initializing && this.state.isAuthenticated ?         
          <div>
            <ReactMarkdown>
              {this.state?.changeLog}         
            </ReactMarkdown>     
          </div>
       
         : 
            <div style={{
                display: "grid",
                gridTemplateRows: "30px 36px", 
                padding: "24px"}}>
                  <Text>
                    Debe iniciar sesión para continuar
                  </Text>
                  <div>                          
                    <Button onClick={() =>{
                      const msalInstance = this.props.msalContext.instance;
                      msalInstance.loginPopup(loginRequest).catch((err: any) => {
                        console.error(err);
                      });
                    }}>Iniciar con cuenta Colbún S.A
                  </Button>   
                </div>                    
            </div>             
         }
      </div>   
    );   
  }
}     
    
export default withAITracking(reactPlugin, withMsal(Changelog));