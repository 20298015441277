import { 
    Button, 
    Dialog, 
    Text, 
    DialogActions, 
    DialogBody, 
    DialogContent,
    DialogSurface, 
    DialogTitle, 
    DialogTrigger, 
    useRestoreFocusTarget,
    tokens} 
from "@fluentui/react-components";
import { RequestHistoryModel } from "../models/RequestHistoryModel";

import {
  ContentView24Regular,
  Eye24Regular
  } from "@fluentui/react-icons";

import React from "react";
import moment from "moment";

export type RequestDetailsDialogProps = {
    prompt: string;
    requestId: string;
    requestDate: Date;
    response: string
    user: string;
    tokens: number;
    requestTime: number;
    liked: number;
    promptTokens: number,
    completionTokens: number,
    plannerTokens: number,
}




// Dialog function component
const RequestDetailsDialog: React.FC<RequestDetailsDialogProps> = (props) => {
//   const { open, onClose, request } = props;

  const [open, setOpen] = React.useState(false);

 

function formatMessageAsHtml(message: string): string | TrustedHTML {
    var htmlMessage = message.replace(/(\n\n)/g, "\n");
    var htmlMessage = htmlMessage.replace(/(\r\n|\r|\n)/g, '<br>');
    return htmlMessage;
}

function formatDate(date: Date): string {
    var momentDate = moment(props.requestDate);
    var resultDate = momentDate.date() === moment().date() ? momentDate.format('HH:mm') : momentDate.format('DD/MM/YYYY HH:mm'); 
    return resultDate;
}

  return (
    <Dialog>
        <DialogTrigger disableButtonEnhancement>
            <Button appearance="transparent" icon={<Eye24Regular/>}></Button>
        </DialogTrigger>

        <DialogSurface>
        <DialogBody>
            <DialogTitle>               
                <Text style={{ color: tokens.colorNeutralForeground3BrandPressed }} weight="bold" size={500}>Detalles de la solicitud</Text>          
             
            </DialogTitle>
            <DialogContent>           
                <div>
                    <Text weight="bold" size={400}>{props.prompt}</Text>   
                </div>
                <div>
                <Text size={300}>De </Text>    
                    <Text> {props.user}</Text>       
                </div>
                <div>
                    <Text size={300}>El </Text>     
                    <Text>{formatDate(props.requestDate)}</Text>     
                 </div>    
                <div style={{marginTop:"8px"}} 
                    dangerouslySetInnerHTML={{__html: formatMessageAsHtml(props.response)}}>
                </div>
                 
                <div style={{marginTop:"8px"}}>
                    <Text weight="bold" size={300}>Tokens: </Text>    
                    <Text align="end" style={{paddingTop:"2px", marginRight: "4px"}}>
                    {props.tokens} ({props.completionTokens} completion / {props.promptTokens} prompt):  
                    </Text>        
                </div>
             
                <div >
                    <Text weight="bold" size={300}>Tiempo de respuesta: </Text>    
                    <Text> {props.requestTime} segundos. </Text>        
                </div>
                       
            </DialogContent>                                          
            <DialogActions>
                <DialogTrigger disableButtonEnhancement>
                    <Button  appearance="secondary">
                        Cerrar
                    </Button>
                </DialogTrigger>               
            </DialogActions>
            </DialogBody>
        </DialogSurface>
    </Dialog>
  );
};

export default RequestDetailsDialog;