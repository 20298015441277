import {
    DataVizPalette,
    AreaChart,
    getColorFromToken,
  } from "@fluentui/react-charting";
  
import React, { FC } from "react";
// import { LineChartData,ChartData,CustomAreaChartProps } from "../../models/ChartModels";


export interface DateChartPoint {
  x: Date;
  y: number;
}

  export interface LineChartData {
  legend: string;
  data: DateChartPoint[];
  color: string;
  opacity: number;
  lineOptions: {
    strokeWidth: number;
    stroke: string;
  };
}


export interface TokensChartProps {
  chart1Points: DateChartPoint[];
  chart2Points: DateChartPoint[];
  colorChart1?: string;
  colorChart2?: string;
  height?: string;
  width?: string;
  legend1?: string;
  legend2?: string;
}

interface ChartData {
  chartTitle: string;
  lineChartData: LineChartData[];
  pointOptions?: {
    r: number;
    strokeWidth: number;
    opacity: number;
    stroke: string;
  };
  pointLineOptions?: {
    strokeWidth: number;
    strokeDasharray: string;
    stroke: string;
  };
}



const TokensChart: FC<TokensChartProps> = ({
  chart1Points,
  chart2Points,
  colorChart1 = DataVizPalette.color1,
  colorChart2 = DataVizPalette.disabled,
  legend1 = "legend1",
  legend2 = "legend2",
}) => {

  const chartPoints: LineChartData[] = [
    {
      legend: legend1,
      data: chart1Points,
      color: colorChart1,
      opacity: 0.7,
      lineOptions: {
        strokeWidth: 3,
        stroke: getColorFromToken(DataVizPalette.color1),
      },
    },
    {
      legend: legend2,
      data: chart2Points,
      color: colorChart2,
      opacity: 0.8,
      lineOptions: {
        strokeWidth: 3,
        stroke: getColorFromToken(DataVizPalette.disabled),
      },
    },
  ];


  const chartData: ChartData = {
    chartTitle: "Area chart styled example",
    lineChartData: chartPoints,
    pointOptions: {
      r: 10,
      strokeWidth: 3,
      opacity: 1,
      stroke: getColorFromToken(DataVizPalette.color1),      
    },
    pointLineOptions: {
      strokeWidth: 2,
      strokeDasharray: "10 10",
      stroke: getColorFromToken(DataVizPalette.color15),
    },
  };

  return (
    <div>
      <AreaChart
        height={80}
        width={300}
        data={chartData}
        enablePerfOptimization={true}
        enableReflow={true}
      />
    </div>
  );
};

export default TokensChart;
