import {
  FluentProvider,
  teamsLightTheme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  tokens,
} from "@fluentui/react-components";

import { useEffect } from "react";
import { HashRouter as Router, Navigate, Route, Routes } from "react-router-dom";
import { app } from "@microsoft/teams-js";
import { useTeamsUserCredential } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import TabConfig from "./TabConfig";
import { TeamsFxContext } from "./Context";
import config from "./config";
import { MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import Chat from "./Chat";
import Changelog from "./Changelog";
import { IPublicClientApplication } from "@azure/msal-browser";
import { reactPlugin } from "../ApplicationInsight/ApplicationInsightsService";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import RequestHistory from "./RequestHistory";


// Props for the App component
type AppProps = {
  pca: IPublicClientApplication;
};


//  The main app which handles the initialization and routing
export default function App({ pca }: AppProps) {
  
  const { loading, theme, themeString, teamsUserCredential } = useTeamsUserCredential({
    initiateLoginEndpoint: config.initiateLoginEndpoint!,
    clientId: config.clientId!,
   });

  useEffect(() => {   
    loading &&
      app.initialize().then(() => {
        console.log("app initialized");
        app.notifySuccess();
      }).catch((err) => {      
        // This fails outside of teams  
     });
  }, [loading]);
    

  return (  
    <AppInsightsContext.Provider value={reactPlugin}>
    <MsalProvider instance={pca}>
      <TeamsFxContext.Provider value={{ theme, themeString, teamsUserCredential }}>
    
        <FluentProvider
          theme={
            themeString === "dark"
              ? teamsDarkTheme
              : themeString === "contrast"
              ? teamsHighContrastTheme
              : {
                  ...teamsLightTheme,
                  colorNeutralBackground3: "#eeeeee",
                }
          }
          style={{ background: tokens.colorNeutralBackground3 }}>
            
                <Router>
                  {!loading && (
                      <Routes>
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/termsOfUse" element={<TermsOfUse />} />
                        <Route path="/chat" element={ <Chat />} />
                        <Route path="/requestHistory" element={ <RequestHistory />} />                        
                        <Route path="/changelog" element={ <Changelog />} />                        
                        <Route path="/config" element={<TabConfig />} />
                        <Route path="*" element={<Navigate to={"/chat"} />}></Route>
                      </Routes>
                  )}
                </Router>          
          
        </FluentProvider>
      
      </TeamsFxContext.Provider>
    </MsalProvider>
    </AppInsightsContext.Provider>
 
  );

}
