

// Custom provider for API Key based authentication
// adding the extra headers needed for the API
export default class ApiKeyAuthProvider {
    apiKeyHeder : string;
    apiKey : string;
    userId : string;
    userEmail : string;
    contentType: string;
  
    constructor(apiKeyHeder: string, apiKey: string, userEmail: string, userId: string, contentType: string) {
      this.apiKeyHeder = apiKeyHeder;
      this.apiKey = apiKey;
      this.userEmail = userEmail;
      this.userId = userId;
      this.contentType = contentType;
    }
  
    AddAuthenticationInfo = async (config: any) => {
      if (!config.headers) {
        config.headers = {};
      }
        config.headers[this.apiKeyHeder] = this.apiKey;

        config.headers["userId"] =  this.userId;
        config.headers["userEmail"] =  this.userEmail;   
        config.headers["Content-Type"] =  this.contentType;   
      return config;
    };
  }