import { FC } from "react";
import { Button, 
  Card, 
  CardHeader, 
  Menu, 
  MenuItem, 
  MenuList, 
  MenuPopover, 
  MenuTrigger, 
  Text,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  Spinner,
  tokens,
} from "@fluentui/react-components";

import {
  FontIncrease24Regular,
  FontDecrease24Regular,
  TextFont24Regular,
  MoreHorizontal24Filled,
  Filter24Regular,
  Save24Regular

} from "@fluentui/react-icons";
import moment from "moment";
import React from "react";

export type ToolbarProps = {
  loading: boolean;
  filterData: (startDate: Date, endDate: Date) => void;
  exportData: () => void;
}



// ToolbarMenu component
const ToolbarMenu: FC<ToolbarProps> = (props) => {     
  
  const [selectedFilter, setSelectedFilter] = React.useState<string>("Mes actual");

    const today = new Date();
    const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59);
    const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0);
 
    const lastMonthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 23, 59);
    lastMonthEndDate.setTime(lastMonthEndDate.getTime() - (1 * 24 * 60 * 60 * 1000));
    const lastMonthStartDate = new Date(lastMonthEndDate.getFullYear(), lastMonthEndDate.getMonth(), 1, 0, 0);

    const last7DaysDate =  new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0);
    last7DaysDate.setTime(last7DaysDate.getTime() - (7 * 24 * 60 * 60 * 1000));

    const last30DaysDate =  new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0);
    last30DaysDate.setTime(last30DaysDate.getTime() - (30 * 24 * 60 * 60 * 1000));

    const last60DaysDate =  new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0);
    last60DaysDate.setTime(last60DaysDate.getTime() - (60 * 24 * 60 * 60 * 1000));
    return (
      <div
          style={{
          display: "grid",
          gridTemplateColumns:"auto 120px",
          backgroundColor: tokens.colorNeutralBackground5Hover,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          padding: "0px",
          marginBottom: "2px",    
        }}>       
        <div> 
          <Toolbar aria-label="Default">                                    
            <Menu>
                <MenuTrigger>
                <Button iconPosition="after" appearance="transparent" icon={<Filter24Regular />} >{selectedFilter}</Button>
                </MenuTrigger>

                <MenuPopover>
                <MenuList>
                    <MenuItem onClick={() => {
                        props.filterData(currentMonthStartDate, currentDate);
                        setSelectedFilter("Mes actual");
                      }}>Mes actual</MenuItem>
                    <MenuItem onClick={() => {
                        props.filterData(lastMonthStartDate, lastMonthEndDate);
                        setSelectedFilter("Mes anterior");
                      }}>Mes anterior</MenuItem>
                    <MenuItem onClick={() => {
                        props.filterData(last60DaysDate, currentDate);
                        setSelectedFilter("Últ. 60 días");
                      }}>Últ. 60 días</MenuItem>
                    <MenuItem onClick={() => {
                      props.filterData(last30DaysDate, currentDate);
                      setSelectedFilter("Últ. 30 días");
                      }}>Últ. 30 días</MenuItem>
                    <MenuItem onClick={() => {
                      props.filterData(last7DaysDate, currentDate);
                      setSelectedFilter("Últ. 7 días");
                      }}>Últ. 7 días</MenuItem>
                </MenuList>
                </MenuPopover>
            </Menu>
            <Button
              onClick={() => props.exportData()} 
              iconPosition="after" appearance="transparent" icon={<Save24Regular />} >Exportar</Button>   
          </Toolbar>   
        </div> 
        <div style={{margin:"8px 0px 0px 0px"}}>
          {props.loading &&   <Spinner size="extra-small" />}               
        </div>
      </div>
    );
}

export default ToolbarMenu;