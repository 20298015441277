import { Button, Card, CardHeader, Text, makeStyles, shorthands } from "@fluentui/react-components";
import { Dismiss16Regular } from "@fluentui/react-icons";
import { ChatModel } from "../models/ChatModel";
import { ChatHistoryModel } from "../models/SearchResultModel";
import moment from "moment";
import Markdown from "react-markdown";

interface ChatHistoryElementProps{
    chat: ChatHistoryModel
    onClick?: () => void
}

interface ChatHistoryProps{
    elements: ChatHistoryModel[]
    onChatSelected: (chat: ChatHistoryModel) => void
    isOpen: Boolean
    onClose: () => void
}


const useStyles = makeStyles({
    title: {
        lineHeight: "1em",
    },
    card: {
        width: "100%",
        height: "fit-content",
        cursor: "pointer",
        ...shorthands.borderRadius("15px"),
    },
    cardDescriptionFooter: {
        marginTop: ".5em",
        fontSize: ".7em",
        textAlign: "right",
    },
    chatHistoryContainer: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "2em",
        paddingRight: "2em",
        ...shorthands.gap("1em"),
        // background-color: #d5d5d5;
        // box-shadow: 4px 0px 8px rgba(0,0,0,0.14) inset;
    },
    closer: {
        display: "none",
        "@media (max-width: 1200px)": {
            display: "block",
            alignSelf: "end"
        }
    },
});

const ChatHistory = (props: ChatHistoryProps) => {
    const styles = useStyles();
    const additionalClasses = ['history-container'];
    if (props.isOpen){
        additionalClasses.push('open');
    }
    return (
        <div className={`${styles.chatHistoryContainer} ${additionalClasses.join(' ')}`}>
            <Button
                  {...props}
                  appearance="transparent"
                  icon={<Dismiss16Regular />}
                  size="small"
                  className={styles.closer}
                  onClick={() => {props.onClose()}}
              />
            <h4>ÚLTIMAS CONSULTAS</h4>
            {props.elements.map( (chat) => 
                <ChatHistoryElement 
                    key={chat.conversationId}
                    chat={chat}
                    onClick={ () => props.onChatSelected(chat)}
                />
            )}
        </div>
    )
}

const ChatHistoryElement = (props: ChatHistoryElementProps) => {
    const styles =  useStyles();
    const date = moment(props.chat.requestDate);
    const timeValue = date.date() === moment().date() ? date.format('HH:mm') : date.format('DD/MM/YYYY HH:mm');
    var chartHistoryItem = props.chat.history[0]; 
    return (
        <Card
        className={styles.card}
        onClick={props.onClick}
        >
        <CardHeader
            header={<div className="chat-history-title">{chartHistoryItem.prompt}</div>}
            description={
                <div>
                    <div className="chat-history-content">
                        <Markdown children={chartHistoryItem.response}/>                       
                    </div>
                    <div className={styles.cardDescriptionFooter}>{timeValue}</div>
                </div>
            }
        />
        </Card>
    )
}

export default ChatHistory;