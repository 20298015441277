import { 
  Button,
  DataGridProps,
  TableCellLayout,
    TableColumnDefinition,
    TableColumnSizingOptions,
    createTableColumn } 
from "@fluentui/react-components";

import {
  DataGrid,
  DataGridBody,
  DataGridHeader,     
  DataGridCell,
  DataGridHeaderCell,
  DataGridRow,
} from "@fluentui-contrib/react-data-grid-react-window";

import { FC } from "react";
import moment from "moment";
import React from "react";
import RequestDetailsDialog from "../RequestDetailsDialog";
  
// Row details with the tokens by user
export type RequestRow  = {
    user: string;
    prompt: string;
    response: string,
    tokens: number;
    promptTokens: number;
    completionTokens: number,
    liked: number;
    date: Date;
    requestTime: number; 
}

// Props to use in this component
export type RequestsTableProps = {
    items: RequestRow[];
}


// Column sizing options
const columnSizingOptions: TableColumnSizingOptions = {
  user: {
    idealWidth: 300,
    minWidth: 80,
  },
  prompt: {
    idealWidth: 300,
    minWidth: 80,
  },
  response: {
    idealWidth: 300,
    minWidth: 80,
  },
  tokens: {
    minWidth: 80,
    defaultWidth: 80,
  },
  date: {
    minWidth: 50,
    defaultWidth: 100,
  },
  liked: {
    minWidth: 50,
    defaultWidth: 50,
  },
  requestTime: {
    minWidth: 80,
    defaultWidth: 80,
  },
  view: {
    minWidth: 50,
    defaultWidth: 50,
  },
}

// Column definition for the grid
const columns: TableColumnDefinition<RequestRow>[] = [
  createTableColumn<RequestRow>({
    columnId: "date",
    compare: (a, b) => {
      return a.user.localeCompare(b.user);
    },
    renderHeaderCell: () => {        
      return "Fecha";
    },
    renderCell: (item) => {
      var momentDate = moment(item.date);
      var date = momentDate.date() === moment().date() ? momentDate.format('HH:mm') : momentDate.format('DD/MM/YYYY HH:mm'); 
      return (      
      <TableCellLayout truncate>
        {date}
      </TableCellLayout>)
    },
  }),
    createTableColumn<RequestRow>({
      columnId: "user",
      compare: (a, b) => {
        return a.user.localeCompare(b.user);
      },
      renderHeaderCell: () => {        
        return "Usuario";
      },
      renderCell: (item) => {
        return (
        <TableCellLayout truncate>
          {item.user}
        </TableCellLayout>)
      },
    }),
    createTableColumn<RequestRow>({
      columnId: "prompt",
      compare: (a, b) => {
        return a.prompt.localeCompare(b.user);
      },
      renderHeaderCell: () => {        
        return "Consulta";
      },
      renderCell: (item) => {
        return (
        <TableCellLayout truncate>
          {item.prompt}
        </TableCellLayout>)
      },
    }),
    createTableColumn<RequestRow>({
      columnId: "response",
      compare: (a, b) => {
        return a.response.localeCompare(b.user);
      },
      renderHeaderCell: () => {        
        return "Respuesta";
      },
      renderCell: (item) => {
        return (
        <TableCellLayout truncate>
          {item.response}
        </TableCellLayout>)
      },
    }),
    createTableColumn<RequestRow>({
        columnId: "tokens",
        compare: (a, b) => {
          return a.tokens < b.tokens ? -1 : 1;
        },
        renderHeaderCell: () => {
          return "Tokens";
        },      
        renderCell: (item) => {
        return (<TableCellLayout>
          {item.tokens.toLocaleString('es-CL')}
        </TableCellLayout>)
        },
    }),
    createTableColumn<RequestRow>({
      columnId: "liked",
      compare: (a, b) => {
        return a.liked < b.liked ? -1 : 1;
      },
      renderHeaderCell: () => {
        return "Like / Dislike";
      },      
      renderCell: (item) => {
      return (<TableCellLayout>
        {item.liked}
      </TableCellLayout>)
      },
    }),
    createTableColumn<RequestRow>({
      columnId: "requestTime",
      compare: (a, b) => {
        return a.requestTime < b.requestTime ? -1 : 1;
      },
      renderHeaderCell: () => {
        return "Tiempo (segundos)";
      },      
      renderCell: (item) => {
      return (<TableCellLayout>
        {item.requestTime }
      </TableCellLayout>)
      },
    }),

    createTableColumn<RequestRow>({
      columnId: "view",
      compare: (a, b) => {
        return a.requestTime < b.requestTime ? -1 : 1;
      },
      renderHeaderCell: () => {
        return "";
      },      
      renderCell: (item) => {
        return (
              <RequestDetailsDialog 
                prompt={item.prompt} 
                requestId={item.requestTime.toString()} 
                requestDate={item.date} 
                response={item.response} 
                user={item.user} 
                tokens={item.tokens} 
                requestTime={item.requestTime} 
                liked={item.liked} 
                promptTokens={item.promptTokens} 
                completionTokens={item.completionTokens} 
                plannerTokens={item.tokens}  />
        )
      }
    }),
];


// Component to show the list of requests
const RequestTable: FC<RequestsTableProps> = ({ items }) => {
      
  const [sortState, setSortState] = React.useState<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >({
    sortColumn: "date",
    sortDirection: "ascending",
  });
  const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
    setSortState(nextSortState);
  };

    return (
        <div>
      
        <DataGrid     
            items={items}
            columns={columns}
            sortable                     
            resizableColumns  
            noNativeElements  
            sortState={sortState}
            onSortChange={onSortChange}
            columnSizingOptions={columnSizingOptions}                 
            getRowId={(item) => item.user}>          
          <DataGridHeader style={{ paddingRight: 12 }}>
              <DataGridRow>
              {({ renderHeaderCell }) => (
                <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
              )}
            </DataGridRow>
          </DataGridHeader>
              <DataGridBody<RequestRow> itemSize={38} height={250}>
                {({ item, rowId }, style) => (
                  <DataGridRow<RequestRow> key={rowId} style={style}>
                    {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                  </DataGridRow>
              )}
          </DataGridBody>        
        </DataGrid>
        </div>
     
    );
  };

  export default RequestTable;
