import { DocumentModel } from "./DocumentModel";

export enum LikeState  {
    None = 0,
    Like = 1,
    Dislike = 2
  }

export interface ChatModel {
    message: string;
    requestId: string;
    role: string;
    date: string;
    likeState: LikeState;
    canLike: boolean;
    backendVersion: string;
    tokens: number;
    documents: DocumentModel[];
    suggestedQuestions: string[];
    requestTime: number;
}
  
  