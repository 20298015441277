import { DonutChart } from "@fluentui/react-charting";
import { FC } from "react";



export type RequestsByLikesPoint = {
    legend: string; 
    data: number;
    color: string;   
    xAxisCalloutData: string;
}

export type RequestsByLikesProps = {
    chartTitle:string;
    width: number | undefined;
    height: number;
    valueInsideDonut: number;
    innerRadius: number;
    legendsOverflowText: string;
    chartData: RequestsByLikesPoint[]
}

// Component to show the list of requests
const RequestsByLikes: FC<RequestsByLikesProps> = (props) => {
    
    return (  
        <div>
            <DonutChart
                culture={window.navigator.language}
                data={props}                
                innerRadius={props.innerRadius}           
                legendsOverflowText={props.legendsOverflowText}
                hideLegend={false}
                height={props.height}
                width={props.width}
                valueInsideDonut={props.valueInsideDonut}/>
        </div>
    );
}

export default RequestsByLikes;