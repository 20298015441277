import React  from "react";
import { Button, 
  Card, 
  CardHeader, 
  Menu, 
  MenuButton, 
  MenuItem, 
  MenuList, 
  MenuPopover, 
  MenuTrigger, 
  Text,
  Toolbar,
  ToolbarButton,
  ToolbarDivider,
  tokens,
} from "@fluentui/react-components";
import {
  FontIncrease24Regular,
  FontDecrease24Regular,
  TextFont24Regular,
  MoreHorizontal24Filled,
  Filter24Regular,
  Save24Regular

} from "@fluentui/react-icons";
import { AxiosInstance, createApiClient } from "@microsoft/teamsfx";
import { EventType } from "@azure/msal-browser";
import ApiKeyAuthProvider from "../Authentication/ApiKeyAuthProvider";
import { withMsal } from "@azure/msal-react";
import { loginRequest } from "../Authentication/MsalConfiguration";
import { app } from "@microsoft/teams-js";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../ApplicationInsight/ApplicationInsightsService";
import { ApiResponse, RequestHistoryResponse } from "../models/ApiResponse";
import { RequestHistoryModel } from "../models/RequestHistoryModel";
import TokensByUserTable, { TokenByUserRow } from "./charts/TokensByUserTable";
import RequestTable, { RequestRow } from "./charts/RequestsTable";
import TokensChart, { DateChartPoint } from "./charts/TokensChart";
import Gauge from "./charts/Gauge";
import ToolbarMenu from "./ToolbarMenu";
import TokensByType, { TokensByTypePoint } from "./charts/TokensByType";
import RequestsByLikes, { RequestsByLikesPoint } from "./charts/RequestsByLikes";
import { DataVizPalette, getColorFromToken } from "@fluentui/react-charting";


type RequestHistoryState = {
  callbackId: String,  
  loading: boolean,  
  userId: string,
  userMail: string,
  isAuthenticated: boolean
  initializing: boolean
  historyStartDate: Date,
  historyEndDate: Date,
  allRequestsHistory: RequestHistoryModel[]
  requestHistory: RequestHistoryModel[]
}


// Request history component
class RequestHistory extends React.Component<any, RequestHistoryState> {
 
  apiClient: AxiosInstance | undefined;
  
  constructor(props: any) {
    super(props);

    const today = new Date();
    const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59);
    const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1, 0, 0);
     
    this.state = {     
      callbackId: "",     
      loading: true,
      userId: "",    
      userMail: "",
      isAuthenticated: false,
      requestHistory: [],
      allRequestsHistory: [],
      historyStartDate:currentMonthStartDate,
      historyEndDate: currentDate,
      initializing: true };        
      this.setupApiClient();    
  }


    // Setup the API client
    setupApiClient() : void {
      const apiKey  = "NUjFZgcXzYhVA1vDPSIdrL-GARq9T3knRDi_fM0H_nZ3AzFuCwkFgA=="; //process.env.REACT_APP_API_KEY ?? "";
      const apiHeader  = process.env.REACT_APP_API_HEADER ?? "";   
      const apiEndpoint  = process.env.REACT_APP_API_ENDPOINT ?? "";
      const authProvider = new ApiKeyAuthProvider(apiHeader, apiKey, this.state.userMail, this.state.userId, "application/json");
      this.apiClient = createApiClient(apiEndpoint, authProvider);
      this.apiClient.defaults.timeout = 60000;
      this.apiClient.defaults.timeoutErrorMessage = "Ocurrió un error al realizar la consulta. Por favor intente nuevamente.";     
    }

  // Component initialization
  // Setups the callback for the login success event
  override async componentDidMount(): Promise<void> {  

     // Handle the message event when authentication is successful with msal
    const callbackId = this.props.msalContext.instance.addEventCallback((message :any) => {     
        if (message.eventType === EventType.LOGIN_SUCCESS) {

          // Saves the user id and email on the state
          const msalInstance = this.props.msalContext.instance;
          const activeAccount = msalInstance.getActiveAccount();
          const userId =  activeAccount?.localAccountId ?? ""; 
          const userMail = activeAccount?.username ?? ""; 
          this.loadRequestHistory(userMail, userId);         
        }
    });

    this.setState({callbackId: callbackId});

    // Tries to authenticate the user via Teams App
    try{
      var context = await app.getContext();
      if(context.user!=null){
        const userId =  context.user?.id ?? "";
        const userMail =  context.user?.userPrincipalName ?? "";
        this.loadRequestHistory(userMail, userId);         
      }
    }
    catch(error){
      // Unable to authenticate use msal      
      console.log(error);    
    }   
  }

  // This will be run on component unmount
  componentWillUnmount() {     
      if (this.state.callbackId) {
          this.props.msalContext.instance.removeEventCallback(this.state.callbackId);
      }
  }

  // Returns the tokens grouped by user
  getTokensByUsers(): TokenByUserRow[] {
    const groupedRequests = this.state.requestHistory.reduce<{ [user: string]: number  }>((a, b) => {
      if (!a[b.email]) {
        a[b.email] = 0;
      }
       a[b.email]  += 1;
      return a;
    }, {});

    const groupedTokens = this.state.requestHistory.reduce<{ [user: string]: number  }>((a, b) => {
      if (!a[b.email]) {
        a[b.email] = 0;
      }
       a[b.email]  += b.tokens;    
      return a;
    }, {});

    return Object.entries(groupedRequests).map(([user, tokens]) => ({ 
        user,         
        requests: groupedTokens[user],
        tokens }));
  }

  // Returns the list of all request
  getAllRequests(): RequestRow[] {

    return this.state.requestHistory.map((item) => {
      return {
        user: item.email,
        prompt: item.prompt,
        response: item.response,
        tokens: item.tokens,
        liked: item.liked,
        date: item.requestDate,
        requestTime: item.requestTime,
        completionTokens:item.completionTokens,
        promptTokens: item.promptTokens,
      }
    }); 
  }


  getTokensChartPoints(): DateChartPoint[] {
    const grouped = this.state.requestHistory.reduce<{ [date: string]: number }>((a, b) => {
      const dateKey = new Date(b.requestDate).getFullYear() + "-" + (new Date(b.requestDate).getMonth() + 1) + "-" + new Date(b.requestDate).getDate();
      if (!a[dateKey]) {
        a[dateKey] = 0;
      }
      a[dateKey] += b.tokens;
      return a;
    }, {});
    return Object.entries(grouped).map(([requestDate, tokens]) => ({  x:  new Date(requestDate),  y:tokens }));  
  }


  getRequestsChartPoints(): DateChartPoint[] {
    const grouped = this.state.requestHistory.reduce<{ [date: string]: number }>((a, b) => {
      const dateKey = new Date(b.requestDate).getFullYear() + "-" + (new Date(b.requestDate).getMonth() + 1) + "-" + new Date(b.requestDate).getDate();   
      if (!a[dateKey]) {
        a[dateKey] = 0;
      }
      a[dateKey] += 1;
      return a;
    }, {});
    return Object.entries(grouped).map(([requestDate, tokens]) => ({  x:  new Date(requestDate),  y:tokens }));  
  }

  // Returns the total number of tokens
  getTotalTokens(): number {
    return this.state.requestHistory.reduce((a, b) => a + b.tokens, 0);
  }

  // Returns the total number of requests
  getTotalRequests(): number {
    return this.state.requestHistory.length;
  } 


  // Load the request history from the service
  loadRequestHistory(userMail: string, userId: string): void { 

    this.setState({userId: userId, userMail: userMail, isAuthenticated: true, initializing: false}, async () =>{
      this.setupApiClient();
      var response = await this.apiClient?.get<RequestHistoryResponse>("requests/history")
        .catch((err: any) => {
          this.showErrorMessage(err);
        });
        
       if (response?.status == 200) {
          var requests  = response.data.data;        
          var filteredRequests = requests.filter((item) => new Date(item.requestDate) >= this.state.historyStartDate 
            && new Date(item.requestDate) <= this.state.historyEndDate);        
          this.setState({ requestHistory: filteredRequests });  
          this.setState({ allRequestsHistory: requests });
          this.setState({ loading: false });
      }     
    });
  };
      

  // Show an error message
  showErrorMessage(err: string): void {
  }
  

  // Returns the request grouped by like / dislikes
  getRequestsByLikes(): RequestsByLikesPoint[] {
    const points: RequestsByLikesPoint[] = [
      { 
          legend: 'Likes', 
          data: this.state.requestHistory.map((item) => item.liked).filter((item) => item == 1).length,
          color: getColorFromToken(DataVizPalette.color1), 
          xAxisCalloutData: 'Me gusta' },
      {
        legend: 'Dislikes',
        data:  this.state.requestHistory.map((item) => item.liked).filter((item) => item == -1).length,
        color: getColorFromToken(DataVizPalette.color2),
        xAxisCalloutData: 'No me gusta',
      },
      {
        legend: 'Sin calificar',
        data:  this.state.requestHistory.map((item) => item.liked).filter((item) => item == 0).length,
        color: getColorFromToken(DataVizPalette.color3),
        xAxisCalloutData: 'Sin calificar',
      },
    ];
    return points;  
  }
  
  
  // Returns the request grouped by token type (prompt, completion)
  getTokensByType(): TokensByTypePoint[] {
    var promptTokens = 0;
    this.state.requestHistory.forEach(x=>promptTokens += x.promptTokens === undefined ||  x.promptTokens === null ? 0 : x.promptTokens);

    var completionTokens = this.getTotalTokens() - promptTokens;

    const points: TokensByTypePoint[] = [
      { 
          legend: 'Completion', 
          data: completionTokens,
          color: getColorFromToken(DataVizPalette.color1), 
          xAxisCalloutData: 'Completion tokens' },
      {
        legend: 'Prompt',
        data: promptTokens,
        color: getColorFromToken(DataVizPalette.color2),
        xAxisCalloutData: 'Prompt tokens',
      },
    ];
    return points;  
  }


  exportData(): void {

    var itemsToExport = this.state.requestHistory.map( (x:any) : RequestRow => ({
      date: x.requestDate,
      user: x.email,
      prompt:  x.prompt,
      response: x.response.replace(/(\r\n|\n|\r)/gm, ""),
      tokens: x.tokens,
      promptTokens: x.promptTokens,
      completionTokens: x.completionTokens,
      liked: x.liked,
      requestTime: x.requestTime      
    }));

    const titleKeys =["Fecha", "Usuario", "Consulta","Respuesta", "Tokens totales", "Prompt tokens", "Completion tokens", "Me gusta", "Tiempo"]

    const refinedData = []
    refinedData.push(titleKeys)

    itemsToExport.forEach(item => {
      refinedData.push(Object.values(item))  
    })

    let csvContent = ''
    refinedData.forEach(row => {
      csvContent += row.join('\t') + '\n'
    })
    
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' })
    const objUrl = URL.createObjectURL(blob)

    var fileName = 'Solicitudes.csv';
    const link = document.createElement('a')
    link.setAttribute('href', objUrl)
    link.setAttribute('download', fileName)
    link.textContent = 'Click to Download'
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    })
    link.dispatchEvent(clickEvt)
    link.remove()
  }

  // Render the component
  render() {
      
     return (
          <div style={{height: "100vh",width: "100vw"}}>    
          {!this.state.initializing && this.state.isAuthenticated ?
              <>
                <div style={{
                  height: "42px",
                  width: "100vw",
                  zIndex: 100,
                  position: "fixed"}}>
                  <ToolbarMenu loading={this.state.loading}           
                    filterData={(startDate: Date, endDate: Date)=>
                    {
                      this.setState({ historyStartDate: startDate });  
                      this.setState({ historyEndDate: endDate });  
                      var filteredRequests = this.state.allRequestsHistory.filter((item) => new Date(item.requestDate) >= startDate 
                        && new Date(item.requestDate) <= endDate);        
                      this.setState({ requestHistory: filteredRequests });             
                    }}
                    exportData={()=>{this.exportData()}}/>
                </div>

                <div style={{width: "100vw", padding:"50px 16px 0px 16px"}}>

                  <div style={{
                    display: "flex",
                    justifyContent:"space-around",
                    gap: "8px 8px",
                    flexWrap: "wrap"}}> 
                    <div style={{flexGrow: "1", minWidth:"300px", width:"320px",  height:"300px"}}>
                      <Card style={{height: "300px"}}>
                        <CardHeader  header={<Text style={{ color: tokens.colorNeutralForeground3BrandPressed }} weight="semibold">Calificación</Text>}></CardHeader>          
                          <RequestsByLikes  width={160} height={180}
                                  chartTitle="Calificación de solicitudes"
                                  innerRadius={50}
                                  valueInsideDonut={this.getTotalRequests()}
                                  legendsOverflowText="Otros"
                                  chartData={this.getRequestsByLikes()} />           
                      </Card>
                    </div>    

                    <div style={{flexGrow: "1", minWidth:"300px", width:"320px", height:"300px"}}>
                      <Card style={{height: "300px"}}>
                        <CardHeader  header={<Text style={{ color: tokens.colorNeutralForeground3BrandPressed }} weight="semibold">Tokens</Text>}></CardHeader> 
                          <TokensByType width={160} height={180}                        
                              chartTitle="Tokens por tipo"
                              innerRadius={50}
                              valueInsideDonut={this.getTotalTokens()}
                              legendsOverflowText="Tokens"
                              chartData={this.getTokensByType()} />            
                      </Card>
                      
                    </div>                
                    <div style={{flexGrow: "2", minWidth:"300px", width:"400px", height:"300px"}}>
                      <Card style={{height: "300px"}}>
                        <CardHeader  header={<Text style={{ color: tokens.colorNeutralForeground3BrandPressed }} weight="semibold">Solicitudes y tokens</Text>}></CardHeader>     
                        <TokensChart  legend1="Tokens" legend2="Solicitudes"
                                    chart1Points={this.getTokensChartPoints()} 
                                    chart2Points={this.getRequestsChartPoints()}  /> 
                      
                      </Card>
      
                    </div>                        
                  </div>
                  
                  <div style={{
                    padding: "8px 0px 0px 0px",
                    display: "flex",
                    justifyContent:"space-around",
                    gap: "8px 8px",
                    flexWrap: "wrap"}}> 
                    <div style={{flexGrow: "3", minWidth:"300px", width:"320px",  height:"400px"}}>
                      <Card style={{height: "400px"}}>
                        <CardHeader  header={<Text style={{ color: tokens.colorNeutralForeground3BrandPressed }} weight="semibold">Solicitudes</Text>}></CardHeader>      
                        <RequestTable items={this.getAllRequests()}/>    
                      </Card>
                        
                    </div>                
                    <div style={{flexGrow: "1", minWidth:"300px", width:"320px",  height:"400px"}}>
                      <Card style={{height: "400px"}}>
                        <CardHeader  header={<Text style={{ color: tokens.colorNeutralForeground3BrandPressed }} weight="semibold">Usuarios</Text>}></CardHeader>    
                        <TokensByUserTable items={this.getTokensByUsers()}/>      
                      </Card>
                    
                    </div>                                                       
                  </div>
                </div>
              </>
            : 
              <>                      
                <div style={{
                    display: "grid",
                    gridTemplateRows: "30px 36px", 
                    padding: "24px"}}>
                      <Text>
                        Debe iniciar sesión para continuar
                      </Text>
                      <div>                          
                        <Button onClick={() =>{
                          const msalInstance = this.props.msalContext.instance;
                          msalInstance.loginPopup(loginRequest).catch((err: any) => {
                            console.error(err);
                          });
                      }}>Iniciar con cuenta Colbún S.A</Button>   
                    </div>                    
                </div>             
              </>
          }            
          </div>   
     );
  } 

}


export default withAITracking(reactPlugin, withMsal(RequestHistory));
