import { DonutChart } from "@fluentui/react-charting";
import { FC } from "react";


export type TokensByTypePoint = {
    legend: string; 
    data: number;
    color: string;   
    xAxisCalloutData: string;
}

export type TokensByTypeProps = {
    chartTitle:string;
    width: number | undefined;
    height: number;
    valueInsideDonut: number;
    innerRadius: number;
    legendsOverflowText: string;
    chartData: TokensByTypePoint[]
}


// Component to show the list of requests
const TokensByType: FC<TokensByTypeProps> = (props) => {

    return (  
        <div>
            <DonutChart
                culture={window.navigator.language}
                data={props}                
                innerRadius={props.innerRadius}           
                legendsOverflowText={props.legendsOverflowText}
                hideLegend={false}
                height={props.height}
                width={props.width}
                valueInsideDonut={props.valueInsideDonut}
            />      
        </div>
    );
}

export default TokensByType;