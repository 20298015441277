import { 
  DataGridProps,
  Spinner,
  TableCellLayout,
    TableColumnDefinition,
    TableColumnSizingOptions,
    createTableColumn } 
from "@fluentui/react-components";

import {
  DataGrid,
  DataGridBody,
  DataGridHeader,     
  DataGridCell,
  DataGridHeaderCell,
  DataGridRow,
} from "@fluentui-contrib/react-data-grid-react-window";

import { FC } from "react";
import React from "react";
  
// Row details with the tokens by user
export type TokenByUserRow  = {
    user: string;
    tokens: number;
    requests: number;
}

// Props to use in this component
export type TokensByUserTableProps = {
    items: TokenByUserRow[];
}


const columnSizingOptions: TableColumnSizingOptions = {
  user: {
    idealWidth: 300,
    minWidth: 150,
  },
  tokens: {
    minWidth: 100,
    defaultWidth: 100,
  },
  requests: {
    minWidth: 50,
    defaultWidth: 50,
  },
}

// Column definition for the grid
const columns: TableColumnDefinition<TokenByUserRow>[] = [
    createTableColumn<TokenByUserRow>({
      columnId: "user",
      compare: (a, b) => {
        return a.user.localeCompare(b.user);
      },
      renderHeaderCell: () => {
        
        return "Usuario";
      },
      renderCell: (item) => {
        return (<TableCellLayout truncate>
          {item.user}
        </TableCellLayout>)
      },
    }),
    createTableColumn<TokenByUserRow>({
        columnId: "tokens",
        compare: (a, b) => {
          return a.tokens < b.tokens ? -1 : 1;
        },
        renderHeaderCell: () => {
          return "Tokens";
        },      
        renderCell: (item) => {
        return (<TableCellLayout>
          {item.tokens.toLocaleString('es-CL')}
        </TableCellLayout>)
        },
    }),
    createTableColumn<TokenByUserRow>({
      columnId: "requests",
      compare: (a, b) => {
        return a.requests < b.requests ? -1 : 1;
      },
      renderHeaderCell: () => {
        return "Solicitudes";
      },      
      renderCell: (item) => {
      return (<TableCellLayout>
        {item.requests.toLocaleString('es-CL')}
      </TableCellLayout>)
      },
  }),
];


// Component to show the tokens by user in a data grid
const TokensByUserTable: FC<TokensByUserTableProps> = ({ items }) => {
       
  const [sortState, setSortState] = React.useState<
    Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
  >({
    sortColumn: "user",
    sortDirection: "ascending",
  });
  const onSortChange: DataGridProps["onSortChange"] = (e, nextSortState) => {
    setSortState(nextSortState);
  };

  
    return (
        <div>
            <DataGrid     
                items={items}
                columns={columns}
                sortable           
                sortState={sortState}
                onSortChange={onSortChange}
                resizableColumns  
                noNativeElements                                    
                columnSizingOptions={columnSizingOptions}                 
                getRowId={(item) => item.user}>          
              <DataGridHeader style={{ paddingRight: 12 }}>
                  <DataGridRow>
                  {({ renderHeaderCell }) => (
                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                  )}
                </DataGridRow>
              </DataGridHeader>
                  <DataGridBody<TokenByUserRow> itemSize={38} height={250}>
                    {({ item, rowId }, style) => (
                      <DataGridRow<TokenByUserRow> key={rowId} style={style}>
                        {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                      </DataGridRow>
                  )}
              </DataGridBody>        
            </DataGrid>          
        </div>
     
    );
  };

  export default TokensByUserTable;
